<template>
  <Layout class="ElectronicCommerce">
    <BHeader>
      <template #right>
        <el-button
          type="prrmary"
          :disabled="radio == 2"
          @click="handleCreateTag"
        >
          新增
        </el-button>
      </template>
    </BHeader>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%;margin-top:10px"
    >
      <el-table-column
        label="序号"
        prop="id"
      />
      <el-table-column
        label="创建时间"
        prop="create_time"
      />
      <el-table-column
        label="标题"
        prop="title"
      />
      <el-table-column
        height="50"
        label="正文预览"
        prop="content"
      >
        <template #default="{ row }">
          <div style="height: 60px;">
            {{ row.content }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <BTextButton
            :disabled="radio == 2"
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditItem(row)"
          />
          <BTextButton
            :disabled="radio == 2"
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />

    <el-dialog
      :title="dialogTile"
      :visible.sync="showDialog"
      width="600px"
      @close="handleDialogClose"
    >
      <el-form
        ref="subForm"
        v-loading="showDialogLoading"
        :model="subForm"
        :rules="subFormRules"
        label-width="100px"
      >
        <el-form-item
          label="类型名称"
          prop="name"
        >
          <el-input
            v-model="subForm.name"
            style="width: 400px;"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleConfirm"
        >确 定</el-button>
      </span>
    </el-dialog>

    <div />
  </Layout>
</template>

<script>
import {
  newsList, addFeedbackType, editFeedbackType, newsDel
} from '@/api/Userfeedback.js'
import { mapState } from 'vuex'
export default {
  name: 'News',
  data () {
    return {
      descTypeList: [{ name: '实物商品', value: 2 }, { name: '虚拟商品', value: 1 }],
      CompanyList: [],
      goodsStatusList: [{ name: '下架', value: 2 }, { name: '上架', value: 1 }],
      exchangeTypeList: [{ name: 'cdk兑换', value: 2 }, { name: '自动发送', value: 1 }],
      action: '',
      batchUploadTemplate: {
        list: [],
        header: ['CDKEY(必填)'],
        fields: ['cdkeys']
      },
      batchUploadVisible: false,
      currentRows: {},
      loadedList: [],
      radio: '1',
      inradio: '1',
      nextradio: '1',
      swiperList: [],
      tagTypeList: [],
      descTypeMap: {},
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      showDialog: false,
      dialogTile: '',
      subForm: {},
      subFormRules: {
        name: [
          { required: true, message: '类型名称必填' }
        ]
      },
      showDialogLoading: false,
      updateLoading: false
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel', 'pkgType'])
  },
  created () {
    this.handleSearch()
  },

  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.listInfo.loading = true
      newsList({
        ...this.formData,
        appId: this.appId,
        channel: this.channel,
        pkgType: this.pkgType
      })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleCreateTag () {
      // this.dialogTile = '新增'
      // this.swiperList = []
      // this.showDialog = true
      this.$router.push('editNews')
    },
    handleEditItem (row) {
      this.$router.push({ path: 'editNews', query: { id: row.id } })
      // this.dialogTile = '编辑'
      // this.showDialog = true
      // this.$set(this.subForm, 'name', row.name)
      // this.subForm.id = row.id
    },
    handleDeleteItem (row) {
      this.$confirm('此操作将删除该类型, 是否继续', '提示')
        .then(() => {
          newsDel({
            id: row.id
          })
            .then(res => {
              if (res.code === 200) {
                this.queryDataList()
                this.$message.success('删除成功')
                this.showDialog = false
              }
            })
            .catch(() => {
              this.showDialog = false
            })
            .finally(() => {
              this.updateLoading = false
              this.showDialogLoading = false
            })
        })
    },
    handleDialogClose () {
      this.subForm = {}
      this.$refs.subForm.resetFields()
    },
    handleConfirm () {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          this.updateLoading = true
          if (this.dialogTile === '新增') {
            addFeedbackType({
              name: this.subForm.name
            })
              .then(res => {
                if (res.code === 200) {
                  this.queryDataList()
                  this.$message.success('新建成功')
                  this.showDialog = false
                }
              })
              .catch(() => {
                this.showDialog = false
              })
              .finally(() => {
                this.updateLoading = false
                this.showDialogLoading = false
              })
          } else {
            editFeedbackType({
              name: this.subForm.name,
              id: this.subForm.id
            })
              .then(res => {
                if (res.code === 200) {
                  this.queryDataList()
                  this.$message.success('修改成功')
                  this.showDialog = false
                }
              })
              .catch(() => {
                this.showDialog = false
              })
              .finally(() => {
                this.updateLoading = false
                this.showDialogLoading = false
              })
          }
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.game-cover {
  margin-top: 20px;
}

.page-description {
  font-size: 14px;
  color: #aaa;
  margin: 30px 0;
}

.header {
  font-size: 16px;
  padding: 0 0 20px 20px;
}

.formcontent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .div {
    width: 200px;
    height: 200px;
    margin: 20px;
    border: 1px #DCDFE6 solid;
    position: relative;
  }

  .content {
    position: absolute;
    text-align: center;
    bottom: -28px;
    left: 0px;
    width: 100%;
  }
}

.ElectronicCommerce {
  .overview {
    display: flex;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;

    .items {
      width: 210px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .num {
        font-size: 26px;
        margin-bottom: 10px;

        .unit {
          font-size: 14px;
        }
      }

      .name {
        font-size: 12px;
      }
    }
  }

  .batchUpload {
    text-align: center;

    .icon-wrapper {
      .upload-icon {
        font-size: 100px;
        color: @primary;
      }
    }

    .batchUpload-tips {
      margin: 20px 0 10px;
    }

    .batchUpload-ctrl-area {
      margin-top: 30px;
      text-align: right;
    }
  }

  .coverUpload {
    display: flex;
    justify-content: center;
  }

  .ctrlWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
